class DefaultParams {
  constructor() {
    this.appInfo = {};
    this.baseUrl = "";
  }

  init(query) {
    const { params, base_url } = query;
    this.appInfo = params ? JSON.parse(decodeURIComponent(params)) : {};
    const host = window.location.host;
    this.baseUrl = base_url
      ? decodeURIComponent(base_url)
      : process.env.VUE_APP_CONSOLE_LOG === "ON" &&
        process.env.NODE_ENV === "production"
      ? "https://" + host.replace(/.*?\./, "www.")
      : process.env.VUE_APP_CONSOLE_LOG === "ON"
      ? "https:www.devtestapp.com"
      : "https://" + host.replace(/.*?\./, "app.");

    if (process.env.VUE_APP_MICRO && process.env.VUE_APP_MICRO === "ON") {
      // micro app in main send baseURL to dev
      this.baseUrl = "https:www.devtestapp.com";
    }
    console.log("this.base_url", this.baseUrl);
  }
  getAppInfo() {
    return this.appInfo || {};
  }
  getBaseUrl() {
    return this.baseUrl;
  }
}

export default new DefaultParams();
