<template>
  <section class="privacy-container">
    <section class="app-featured-image-container">
      <img
        src="@/assets/img/14.webp"
        title="About Banner@2x" alt="About Banner@2x" class="featured-image">
      <div class="overlay">
        <div class="title-container">
          <div class="title-content"><h1 style="text-align: center;">Privacy Policy</h1>
            <h2 style="text-align: center;">How we gather, use, disclose and manage data from the apps</h2></div>
        </div>
      </div>
      <div class="motif align-bottom"><img
          src="https://www.spinysoftware.com/wp-content/themes/spiny-software/assets/svg/motif.svg"></div>
    </section>

    <PrivacyContent></PrivacyContent>
  </section>
</template>
<script>
import PrivacyContent from "@/components/PrivacyTerms/PrivacyContent";

export default {
  components: {
    PrivacyContent
  }
};
</script>

<style scoped lang="scss">
.app-featured-image-container {
  min-height: 25rem;
  max-height: 50vh;
  position: relative;
  margin-bottom: 64px;
  .featured-image {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 25rem;
    max-height: 50vh;
    height: 100%;
  }
  .overlay {
    background: linear-gradient(to right, rgba(var(--secondary-500-rgb), 0.3) 0%, rgba(var(--secondary-100-rgb), 0.3) 100%);
    z-index: 2;
    padding: calc( var(--header-height) + var(--p4)) var(--p2) var(--p4);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 25rem;
    max-height: 50vh;
    height: 100%;
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      z-index: 2;
      .title-content {
        max-width: calc( 68rem * .6666);
        width: 100%;
        margin: 0 auto;
        text-align: center;
        h1 {
          font-size: 1.875em;
          line-height: 1.2667;
          font-weight: 900;
          color: #fff;
        }
        h2 {
          margin-top: 1rem;
          color: #fff;
          font-size: 1rem;
        }
        @media (min-width: 48rem) {
          h1 {
            font-size: 3.125em;
          }
        }
      }
    }
  }
  .motif {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    z-index: 12;
    left: 0;
    mix-blend-mode: luminosity;
    background-blend-mode: luminosity;
    &.align-bottom {
      bottom: 0;
      transform: translateY(50%);
    }
    img {
      object-fit: fill;
      max-width: none;
      width: 120vw;
      mix-blend-mode: luminosity;
      background-blend-mode: luminosity;
      margin-left: -10vw;
      max-height: 6.25em;
    }
  }
}
@media (min-width: 960px) {
  .privacy-container {
    padding-bottom: 88px;
    .title-content {
      h2 {
        font-size: 1.5625em!important;
      }
    }
  }
  .app-featured-image-container {
    min-height: 35rem;
    .featured-image {
      min-height: 35rem;
    }
    .overlay {
      min-height: 35rem;
    }
  }
}
</style>
