<template>
  <header class="siteHeader siteHeader-navigation">
    <div class="siteHeader__wrapper">
      <svg width="32" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.559 11.605a5.158 5.158 0 1 0 0 10.317 5.158 5.158 0 0 0 0-10.317Zm-13.401.001a5.158 5.158 0 1 0 0 10.315 5.158 5.158 0 0 0 0-10.315Zm11.858-6.448a5.158 5.158 0 1 1-10.316 0 5.158 5.158 0 0 1 10.316 0Z"
            fill="#F06A6A"></path>
      </svg>
      <router-link to="/" class="link">Anime Art</router-link>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.siteHeader {
  justify-content: space-between;
  transition: padding 225ms, transform 300ms ease-out;
  width: 100%;
  z-index: 900;
  box-shadow: none;
  top: 0;
  position: fixed;
  background-color: #FFFFFF;
  height: 56px;

  .siteHeader__wrapper {
    display: flex;
    height: 100%;
    max-width: 1420px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 16px 0;
    margin: 0 auto;
    .link {
      text-decoration: none;
      color: #0D0E10;
      font-weight: 400;
      font-size: 22px;
    }
  }
  @media (min-width: 48em) {
    .siteHeader__wrapper {
      padding: 0 24px 0;
    }
  }
  @media (min-width: 70em) {
    .siteHeader__wrapper {
      padding: 0 64px 0;
    }
  }
}
@media (max-width: 69.9375em) {
  .siteHeader {
    position: fixed;
    box-shadow: 0 2px 12px 0 rgba(36, 50, 66, 0.08);
  }
}
</style>
