import Vue from "vue";
import VueRouter from "vue-router";
import PrivacyPage from "../views/PrivacyTerms/PrivacyPage.vue";
import TermsPage from "../views/PrivacyTerms/TermsPage.vue";
import Contact from "../views/Contact/Contact.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/contact-us'
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },

  {
    path: "/read-privacy",
    name: "privacy",
    component: PrivacyPage,
  },
  {
    path: "/read-terms",
    name: "terms",
    component: TermsPage,
  }
];


const routerInstance = () => {
  // write name into path:
  const name = process.env.VUE_APP_NAME;
  const router = new VueRouter({
    mode: "history",
    base: window.__POWERED_BY_QIANKUN__
      ? `/micro/${name}/`
      : process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    // 检查是否需要滑到顶部的路由，如有需要则滑动到顶部
    window.scrollTo(0, 0);
    next();
  });

  return router;
};

export default routerInstance;
