<template>
  <section class="sectionBuilder -pt-0 -pb-0 -first css-0" id="sales-form">
    <div class="container -full">
      <section class="content">
        <h3 class="left-wrapper">
          Talk with us
          <p class="css-1uh3ryn e152ujn45">
            If you notice any omissions in the app, have a question, suggestion about a feature, or just would like to say hi - we would love to hear from you.
          </p>
        </h3>
        <div class="right-wrapper">
          <div id="marketoForm">
            <form class="forms"
              style="font-family: Helvetica, Arial, sans-serif; font-size: 13px; color: rgb(51, 51, 51);"
            >
              <div class="mktoFormRow">
                <div class="mktoFieldDescriptor mktoFormCol" style="margin-bottom: 10px;">
                  <div class="mktoOffset" style="width: 10px;"></div>
                  <div class="mktoFieldWrap mktoRequiredField">
                    <label
                        for="Name"
                        id="LblLastName"
                        class="mktoLabel mktoHasWidth"
                        style="width: 285px;">
                      <div class="mktoAsterix">*</div>
                      Name
                    </label>
                    <div class="mktoGutter mktoHasWidth" style="width: 10px;"></div>
                    <input id="Name" name="Name" placeholder="Your name" maxlength="255"
                           type="text"
                           @keyup="changeText('name')"
                           @blur="blurText('name')"
                           v-model="name"
                           class="mktoField mktoTextField mktoHasWidth mktoRequired"
                           style="width: 285px;">
                    <div v-show="nameError" class="mktoError" style="right: 102.5px; bottom: -34px;">
                      <div class="mktoErrorArrowWrap">
                        <div class="mktoErrorArrow"></div>
                      </div>
                      <div role="alert" tabindex="-1" class="mktoErrorMsg">
                        {{ nameError }}
                      </div>
                    </div>
                    <div class="mktoClear"></div>
                  </div>
                  <div class="mktoClear"></div>
                </div>
                <div class="mktoClear"></div>
              </div>
              <div class="mktoFormRow">
                <div class="mktoFieldDescriptor mktoFormCol" style="margin-bottom: 10px;">
                  <div class="mktoOffset" style="width: 10px;"></div>
                  <div class="mktoFieldWrap mktoRequiredField">
                    <label
                      for="Email" id="LblEmail"
                      class="mktoLabel mktoHasWidth"
                      style="width: 285px;"
                    >
                      <div class="mktoAsterix">*</div>
                      Email
                    </label>
                    <div class="mktoGutter mktoHasWidth" style="width: 10px;"></div>
                    <input id="Email"
                           name="Email"
                           @keyup="changeText('email')"
                           @blur="blurText('email')"
                           v-model="email"
                           placeholder="Your email"
                           type="email"
                           class="mktoField mktoEmailField mktoHasWidth mktoRequired"
                           style="width: 285px;">
                    <div v-show="emailError" class="mktoError email_error" >
                      <div class="mktoErrorArrowWrap">
                        <div class="mktoErrorArrow"></div>
                      </div>
                      <div role="alert" tabindex="-1" class="mktoErrorMsg email_error">
                        {{ emailError }}
                      </div>
                    </div>
                    <div class="mktoClear"></div>
                  </div>
                  <div class="mktoClear"></div>
                </div>
                <div class="mktoClear"></div>
              </div>
              <div class="mktoFormRow all">
                <div class="mktoFieldDescriptor mktoFormCol" style="margin-bottom: 10px;">
                  <div class="mktoOffset" style="width: 10px;"></div>
                  <div class="mktoFieldWrap mktoRequiredField">
                    <label
                      for="Web_Form_Note__c"
                      id="LblWeb_Form_Note__c"
                      class="mktoLabel mktoHasWidth"
                      style="width: 285px;"
                    >
                    <div class="mktoAsterix">*</div>
                    What would you like to discuss?</label>
                    <div class="mktoGutter mktoHasWidth" style="width: 10px;"></div>
                    <textarea
                      id="Web_Form_Note__c"
                      name="Web_Form_Note__c"
                      placeholder="Got a suggestion, question, or bug report? Send a message to us."
                      rows="2"
                      v-model="message"
                      class="mktoField textarea"
                      @keyup="changeText('message')"
                      @blur="blurText('message')"
                    >
                    </textarea>
                    <div v-show="messageError" class="mktoError">
                      <div class="mktoErrorArrowWrap">
                        <div class="mktoErrorArrow"></div>
                      </div>
                      <div role="alert" tabindex="-1" class="mktoErrorMsg message_error">
                        {{ messageError }}
                      </div>
                    </div>
                    <div class="mktoClear"></div>
                  </div>
                  <div class="mktoClear"></div>
                </div>
                <div class="mktoClear"></div>
              </div>
              <div class="mktoButtonRow">
                <button @click.stop.prevent="clickSubmit" class="mktoButton">Submit</button>
              </div>
            </form>
            <p class="desc">Fields marked with an asterisk (*) are required.</p>
          </div>
        </div>
      </section>
    </div>
    <Toast v-model="toastValue" :error="errorToast"></Toast>
  </section>
</template>
<script>

import CachSendEmail from "@/utils/cach_send_email";
import DefaultParams from "@/utils/defalut_params";
import {sendEmail} from "@/api/question";
import Toast from "@/components/Toast/Toast";

export default {
  components: {
    Toast
  },
  data() {
    return {
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      message: "",
      messageError: "",
      toastValue: "",
      errorToast: false
    }
  },
  methods: {
    changeText(type) {
      if (type === 'name' && this.name && this.name.trim() && this.nameError) {
        this.nameError = '';
      } else if (type === 'email' && this.email && this.emailError && this.email.trim()) {
        this.emailError = '';
      } else if (type === 'message' && this.message && this.messageError && this.message.trim()) {
        this.messageError = '';
      }
    },
    blurText(type) {
      if (type === 'name' && (!this.name || !this.name.trim())) {
        this.nameError = "Please Enter Name.";
      } else if (type === 'email' && (!this.email || !this.email.trim())) {
        this.emailError = "Please enter a valid email address.";
      } else if (type === 'message' && (!this.message || !this.message.trim())) {
        this.messageError = "Please Enter Message.";
      }
    },
    clickSubmit() {
      const canSendEmail = CachSendEmail.sendEmailEnable();
      this.emailError =
          this.nameError =
              this.subjectError =
                  this.messageError =
                      "";
      if (!canSendEmail) {
        // 一个小时内超过两次，禁止发送
        this.errorToast = true;
        this.toastValue = "You operation are too frequent. Take a break!";
        return;
      }

      if (!this.name) {
        this.nameError = "Please Enter Name.";
        return;
      }
      let re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
      if (!this.email || !re.test(this.email)) {
        this.emailError = "Please enter a valid email address.";
        return;
      }
      if (!this.message) {
        this.messageError = "Please Enter Message.";
        return;
      }

      const nativeParams = { ...DefaultParams.getAppInfo() };
      const access_token = nativeParams.access_token;
      delete nativeParams["access_token"];
      const body = {
        ...nativeParams,
        feedback: {
          // question_type: this.selectValue.index,
          question: this.subject,
          name: this.name,
          email: this.email,
          suggestion: this.message
        }
      };
      console.log("feedback", {
        // question_type: this.selectValue.index,
        question: this.subject,
        name: this.name,
        email: this.email,
        suggestion: this.message
      });
      sendEmail(body, access_token)
          .then(() => {
            CachSendEmail.storageSendParams();
            this.errorToast = false;
            this.toastValue = "Thanks for submitting.";
            this.clearFrom();
          })
          .catch(err => {
            console.log("clickSendEmail err", err);
            this.errorToast = true;
            this.toastValue = "Something went wrong.";
          });
    },
    clearFrom() {
      this.name = this.email = this.subject = this.message = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.sectionBuilder {
  padding-top: 0;
  position: relative;
  //padding-bottom: 80px;
  @media (min-width: 48em) {
    .sectionBuilder {
      padding-bottom: 160px;
      padding-top: 160px;
    }
  }
  @media (min-width: 60em) {
    .sectionBuilder {
      padding-top: 240px;
    }
  }
  .container {
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    transition: width 150ms;
    .content {
      position: relative;
      padding: 105px 0 48px;
      background: none;
      margin: 0 4.3%;
      display: grid;
      column-gap: 8px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      row-gap: 0 !important;
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        background-color: rgb(238, 235, 234);
        width: 100vw;
        height: 100%;
        z-index: -1;
        transform: translateX(-4.3%);
      }
      .left-wrapper {
        grid-column: 1/span 4;
        grid-row: 1/span 1;
        text-align: center;
        font-size: 24px;
        line-height: 36px;
        color: #0D0E10;
        font-weight: normal;
        p {
          grid-column: 1/span 4;
          grid-row: 2/span 1;
          text-align: center;
          font-size: 12px;
          line-height: 20px;
          color: #0D0E10;
          margin-bottom: 48px!important;
          margin-top: 16px;
        }
      }
      .right-wrapper {
        grid-column: 1/span 4;
        grid-row: 3/span 1;
        background-color: #FFFFFF;
        padding: 32px 22px 48px;
        border-radius: 5px;
        .forms {
          padding: 0;
          width: 100%;
          text-align: left;
          .mktoFormRow {
            clear: both;
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 16px;
            .mktoFormCol {
              margin: 0;
              float: none;
              position: relative;
              min-height: 2em;
              text-align: left;
              padding: 0;
              .mktoFieldWrap {
                float: none;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-start;
                .mktoLabel {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  font-size: 14px;
                  width: auto !important;
                  float: left;
                  font-weight: 500;
                  color: #273240;
                  line-height: 24px;
                  padding-top: 0;
                  margin-bottom: 16px;
                  font-family: "gordita", "Helvetica Neue", "Helvetica", sans-serif;
                  .mktoAsterix {
                    color: #F2A100;
                    float: none;
                    display: inline-block;
                    order: 2;
                    padding-left: 5px;
                  }
                }
                .mktoField {
                  border: 1px solid #727272;
                  border-radius: 3px;
                  color: #0D0E10;
                  cursor: text;
                  box-shadow: none;
                  width: 100% !important;
                  font-size: 16px;
                  font-weight: 400;
                  margin-bottom: 5px;
                  background-color: #FFFFFF;
                  height: 50px;
                  line-height: normal;
                  padding: 0 0 0 16px;
                  transition: border 150ms;
                  box-sizing: border-box;
                  &.textarea {
                    border: 1px solid #727272;
                    border-radius: 3px;
                    font-size: 14px;
                    padding: 15px;
                    height: 150px;
                    color: #0D0E10;
                  }
                }
                .mktoError {
                  position: absolute;
                  z-index: 99;
                  color: #bf0000;
                  bottom: -34px;
                  left: 14px;
                  .mktoErrorArrowWrap {
                    width: 16px;
                    height: 8px;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 5px;
                    z-index: 100;
                    .mktoErrorArrow {
                      background-color: #e51b00;
                      border: 1px solid #9f1300;
                      border-right: none;
                      border-bottom: none;
                      display: inline-block;
                      height: 16px;
                      -webkit-transform: rotate(45deg);
                      -moz-transform: rotate(45deg);
                      transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      width: 16px;
                      margin-top: 5px;
                    }
                  }
                  .mktoErrorMsg {
                    display: block;
                    margin-top: 7px;
                    background-color: #e51b00;
                    background-image: linear-gradient(#e51b00 43%, #ba1600 100%);
                    border: 1px solid #9f1300;
                    -webkit-border-radius: 6px;
                    border-radius: 6px;
                    -webkit-box-shadow: rgba(0,0,0,0.65) 0 2px 7px, inset #ff3c3c 0 1px 0px;
                    box-shadow: rgba(0,0,0,0.65) 0 2px 7px, inset #ff3c3c 0 1px 0px;
                    color: #f3f3f3;
                    font-size: 1em;
                    line-height: 1.2em;
                    padding: 0.4em 0.6em;
                    text-shadow: #901100 0 -1px 0;
                    &.email_error {
                      width: 16em;
                    }
                  }
                }
              }
            }
          }
          .mktoButtonRow {
            min-width: 162px;
            width: auto;
            font-size: 14px;
            display: block;
            margin-top: 0;
            position: relative;
            .mktoButton {
              line-height: 1;
              display: block;
              font-family: "gordita", "Helvetica Neue", "Helvetica", sans-serif;
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;
              padding: 16px 32px !important;
              background-image: none;
              width: 100%;
              height: 50px;
              background-color: #0D0E10;
              border-radius: 3px;
              border: none;
              color: #FFFFFF;
              cursor: pointer;
              letter-spacing: 0.5px;
              text-align: center;
              text-decoration: none;
              transition: background-color 150ms, color 150ms ease-in-out;
              &:hover {
                background-color: #F06A6A;
                border-bottom: none;
                color: #0D0E10;
                text-decoration: none;
              }
            }
          }
        }
        .desc {
          color: rgb(242, 161, 0);
          font-size: 14px;
          line-height: 24px;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    }
    @media (min-width: 480px) {
      .content {
        margin: 0 5%;
        &:before {
          transform: translateX(-5%);
        }
      }
    }
    @media (min-width: 768px) {
      .content {
        grid-template-columns: repeat(8, 1fr);
        column-gap: 16px;
        margin: 0 4.3%;
        &:before {
          transform: translateX(-4.3%);
        }
        .left-wrapper {
          grid-column: 1/span 8;
          p {
            grid-column: 1/span 8;
          }
        }
        .right-wrapper {
          grid-column: 1/span 8;
          .forms {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .mktoFormRow {
              -webkit-flex-basis: 49%;
              -ms-flex-preferred-size: 49%;
              flex-basis: 49%;
              max-width: 49%;
              &.all {
                -webkit-flex-basis: 100%;
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 16px;
              }
              .mktoLabel {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
    @media (min-width: 960px) {
      .content {
        grid-template-columns: repeat(12, 1fr);
        column-gap: 16px;
        margin: 0 3.3%;
        grid-template-rows: min-content min-content 1fr;
        &:before {
          transform: translateX(-3.3%);
          height: 55%;
        }
        .left-wrapper {
          font-size: 32px;
          line-height: 44px;
          margin-bottom: 16px;
          padding: 32px 0 0;
          border-radius: 5px 5px 0 0;
          text-align: left;
          grid-column: 1/span 4;
          background: none;
          font-weight: normal;
          p {
            font-size: 16px;
            line-height: 28px;
            text-align: left;
            grid-column: 1/span 5;
            grid-row: 3/span 1;
            padding-top: 16px;
            margin-top: 0;
          }
        }
        .right-wrapper {
          border-radius: 0 0 5px 5px;
          box-shadow: 0 30px 30px rgba(36, 50, 66, 0.14);
          padding: 32px 32px 32px;
          grid-column: 5/span 8;
          grid-row: 1/span 1;
        }
      }
    }
    @media (min-width: 1120px) {
      .content {
        column-gap: 24px;
        margin: 0 5.7%;
        &:before {
          transform: translateX(-5.7%);
        }
        .left-wrapper {
          padding-bottom: 16px;
        }
      }
    }
    @media (min-width: 1440px) {
      .content {
        column-gap: 32px;
        margin: 0 auto;
        max-width: 1288px;
        &:before {
          transform: translateX(calc((-100vw + 1288px) / 2));
        }
      }
    }
    @media (min-width: 1680px) {
      .content {
        column-gap: 32px;
      }
    }
  }
}
@media (min-width: 960px) {
  .sectionBuilder {
    padding-bottom: 88px;
  }
}
</style>
