<template>
  <footer class="siteFooter">
    <div class="siteFooter__secondary__wrapper">
      <div class="container">
        <div class="siteFooter__row">
          <div class="siteFooter__secondary__col-wrapper">
            <div class="siteFooter__secondary__col">© 2023 Anime Art, Inc.</div>
            <div class="siteFooter__secondary__col siteFooter__secondary__col--terms">
              <div>
                <router-link class="link" style="margin-right: 10px;" to="/read-privacy">Privacy</router-link>
                <router-link class="link" style="margin-left: 10px;margin-right: 10px;" to="/read-terms">Terms</router-link>
                <router-link class="link" style="margin-left: 10px;" to="/contact-us">Contact</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.path === "/";
    }
  },
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>
<style scoped lang="scss">
.siteFooter {
  position: relative;
  background-color: #151B26;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  .siteFooter__secondary__wrapper {
    background-color: #2A2B2C;
    color: #FFFFFF;
    padding-bottom: 32px;
    padding-top: 32px;

    .container {
      max-width: 1312px;
      width: calc(100% - (4 * 32px));
      box-sizing: border-box;
      margin: 0 auto;

      .siteFooter__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .siteFooter__secondary__col-wrapper {
          flex-basis: 100%;
          padding: 0 8.3333333333%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: center;

          .siteFooter__secondary__col {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
            flex-basis: 100%;
            .link {
              color: white;
              text-decoration: none;
            }
          }
          @media (min-width: 60em) {
            .siteFooter__secondary__col {
              margin-bottom: 0;
              flex-basis: auto;
            }
          }
        }
      }
    }

    @media (max-width: 48em) {
      .container {
        width: calc(100% - 96px);
      }
    }
    @media (max-width: 30em) {
      .container {
        width: calc(100% - 32px);
      }
    }
  }
}
@media (min-width: 960px) {
  .siteFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
